import { UtteranceModel, WordModel } from "../../transcript/core/_models";
import { ThemeModeType } from "../../../../_metronic/partials";
import {
  AllSearchResults,
  ChapterModel,
  EventWordModel,
  SemanticSearchResult,
  TagModel,
  TextSearchResult,
  TranscriptModel,
  WordChapterModel,
} from "./_models";
import {
  getAbsoluteOffsetTop,
  hexToRGBA,
} from "../../transcript/core/_helpers";
import { off } from "process";
import { end } from "@popperjs/core";

export const calculateCountdown = (startDate: string) => {
  const targetDate = new Date(startDate);
  const now = new Date();
  const difference = targetDate.getTime() - now.getTime();

  if (difference < 0) {
    return { countdown: "Event will start soon!", startRefreshing: true };
  } else if (difference < 300000) {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      countdown: `${days}d ${hours}h ${minutes}m ${seconds}s`,
      startRefreshing: true,
    };
  } else {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return {
      countdown: `${days}d ${hours}h ${minutes}m ${seconds}s`,
      startRefreshing: false,
    };
  }
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};

export const formatQuestionDate = (dateString: string) => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return formattedDate;
};

export const convertStringToMiliSeconds = (time: string) => {
  const timeArray = time.split(":");
  const hours = parseInt(timeArray[0]);
  const minutes = parseInt(timeArray[1]);
  const seconds = parseInt(timeArray[2]);

  var timeInSec = hours * 3600000 + minutes * 60000 + seconds * 1000;

  return timeInSec;
};

export const formatAnswerTime = (time: number) => {
  time = Math.floor(time / 1000);
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const formattedTime =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return formattedTime;
};

export const calculateTime = (questionDate: string): string => {
  const date = new Date(questionDate);
  const now = new Date();
  const difference = now.getTime() - date.getTime();

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  if (days > 0) return `${days} days ago`;

  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  if (hours > 0) return `${hours} hours ago`;

  const minutes = Math.floor((difference / 1000 / 60) % 60);
  return `${minutes} minutes ago`;
};

export const anonymizeName = (name: string) => {
  if (!name) return "";
  return name[0] + "*".repeat(4) + " " + "*".repeat(5);
};

export const highlightResults = (
  word: WordModel,
  results?: (TextSearchResult | SemanticSearchResult)[],
  currentIndex?: number
) => {
  var colors = {
    yellow: "highlight-yellow",
    orange: "highlight-orange",
    transparent: "",
  };

  let classToApply = colors.transparent;

  if (results) {
    for (let r = 0; r < results.length; r++) {
      if (word.start >= results[r].start && word.end <= results[r].end) {
        classToApply = colors.yellow;
      }
    }
    return classToApply;
  } else {
    classToApply = colors.transparent;
    return classToApply;
  }
};

export const scrollToResult = (
  results: AllSearchResults,
  currentIdex: number
) => {
  let allSpans = document.getElementsByClassName("highlights-wrapper");

  if (allSpans.length == 0) {
    return;
  }

  for (let i = 0; i < allSpans.length; i++) {
    var spanStart = allSpans[i] as HTMLDivElement;
    if (spanStart) {
      if (
        Number(spanStart.getAttribute("data-start")) ===
        results.results[currentIdex].start
      ) {
        var scollableElement = document.getElementById("srollable-transcript");
        if (spanStart && scollableElement) {
          const containerHeight = scollableElement.clientHeight;
          scollableElement.scrollTop =
            spanStart.offsetTop - containerHeight / 2 - 100;
        }
      }
    }
  }
};

export const applySentimentColors = (word: EventWordModel) => {
  var colors = {
    neutral: "",
    positive: "positive-sentiment",
    negative: "negative-sentiment",
  };

  if (word.sentiment == "neutral") {
    return colors.neutral;
  }
  if (word.sentiment == "positive") {
    return colors.positive;
  }
  return colors.negative;
};

export const applySentimentForPopup = (
  calculatedMode: ThemeModeType,
  sentiment: string
) => {
  if (calculatedMode == "dark") {
    var colors = {
      positive: "#1d801d",
      negative: "#a31717",
    };
  } else {
    var colors = {
      positive: "#8bfc8ba2",
      negative: "#fa7d7d96",
    };
  }
  if (sentiment == "positive") {
    return colors.positive;
  }
  return colors.negative;
};

export const groupWordsByChapter = (
  utterances: UtteranceModel[],
  words: EventWordModel[],
  chapters?: ChapterModel[]
) => {
  const newWordChapters: WordChapterModel[] = words.map((word) =>
    mapWordToChapter(word, utterances, chapters)
  );
  return groupWordsByUtterancesAndChapters(newWordChapters);
};

const mapWordToChapter = (
  word: EventWordModel,
  utterances: UtteranceModel[],
  chapters?: ChapterModel[]
): WordChapterModel => {
  let utteranceIndex = 0;

  // Check if the word's end time is larger than the current utterance's end time
  while (
    utteranceIndex < utterances.length &&
    word.end > utterances[utteranceIndex].end
  ) {
    utteranceIndex++;
  }

  const currentUtterance = utterances[utteranceIndex];
  if(chapters){
    for (let chapter of chapters) {
      if (word.start >= chapter.start && word.start <= chapter.end) {
        return { word, chapter, utterance: currentUtterance };
      }
    }
  }
  
  return { word, utterance: currentUtterance };
};

const groupWordsByUtterancesAndChapters = (
  newWordChapters: WordChapterModel[]
): WordChapterModel[][] => {
  const splitWordChapters: WordChapterModel[][] = [];
  let lastChapterStart: number | null = null;
  let lastUtteranceEnd: number | null = null;

  newWordChapters.forEach((item) => {
    if (item.chapter) {
      if (
        lastChapterStart === item.chapter.start &&
        (lastUtteranceEnd === null || item.word.end <= lastUtteranceEnd)
      ) {
        splitWordChapters[splitWordChapters.length - 1].push(item);
      } else {
        splitWordChapters.push([item]);
        lastChapterStart = item.chapter.start;
      }
    } else {
      if (
        lastChapterStart !== null ||
        splitWordChapters.length === 0 ||
        (lastUtteranceEnd !== null && item.word.end > lastUtteranceEnd)
      ) {
        splitWordChapters.push([item]);
        lastChapterStart = null;
      } else {
        splitWordChapters[splitWordChapters.length - 1].push(item);
      }
    }
    lastUtteranceEnd = item.utterance!.end;
  });

  return splitWordChapters;
};

export const updateHeaderTitle = (isIntersecting: boolean) => {
  const eventTitle = document.getElementById("header-event-title");
  if (isIntersecting && eventTitle) {
    eventTitle.classList.remove("visible");
  }
  if (!isIntersecting && eventTitle) {
    eventTitle.classList.add("visible");
  }
};

export const searchForPhrases = (
  searchWords: string[],
  caseSensitiveSearch: boolean,
  transcript?: TranscriptModel
) => {
  if (!transcript) return null;
  const words = getWordsFromTranscript(transcript);
  let textSearchResults: TextSearchResult[] = [];

  if (words.length == 0) {
    return null;
  }

  for (let i = 0; i < words.length; i++) {
    let sequence = words.slice(i, i + searchWords.length).map((word) => word.text.trim());
    let searchSequence = [...searchWords];

    if (!caseSensitiveSearch) {
        sequence = sequence.map((word) => word.toLowerCase());
        searchSequence = searchSequence.map((word) => word.toLowerCase());
    }

    let searchPhrase = searchSequence.join(' ');
    let sequenceText = sequence.join(' ');

    let searchIndex = sequenceText.indexOf(searchPhrase);
    if (searchIndex !== -1) {
        for (let j = 0; j < searchWords.length; j++) {
            let offsetLeft = j === 0 ? searchIndex : 0;
            let offsetRight = j === searchWords.length - 1 ? sequenceText.length - (searchIndex + searchPhrase.length) : 0;

            const textSearchResult: TextSearchResult = {
                start: words[i + j].start,
                end: words[i + j].end,
                text: words[i + j].text,
                speaker: words[i + j].speaker,
                offsetLeft: offsetLeft,
                offsetRight: offsetRight,
                isPartial: true,
            };
            textSearchResults.push(textSearchResult);
        }
    }
  }
  return textSearchResults;
};

export const getWordsFromTranscript = (transcript: TranscriptModel) => {
  let words: EventWordModel[] = [];
  const transcriptCopy = { ...transcript };

  for (let u = 0; u < transcriptCopy.utterances.length; u++) {
    for (let s = 0; s < transcriptCopy.utterances[u].sentences.length; s++) {
      for (
        let w = 0;
        w < transcriptCopy.utterances[u].sentences[s].words.length;
        w++
      ) {
        words.push(transcriptCopy.utterances[u].sentences[s].words[w]);
      }
    }
  }

  return words;
};


export const removeHighlights = () => {
  let allSpans = document.getElementsByClassName("event-word");

  if (allSpans.length == 0) {
    return;
  }

  for (let i = 0; i < allSpans.length; i++) {
    allSpans[i].classList.remove("highlight-orange");
    allSpans[i].classList.remove("highlight-yellow");
  }
};

export const applyHighlight = (
  result: TextSearchResult | SemanticSearchResult
) => {
  let allSpans = document.getElementsByClassName("event-word");

  if (allSpans == null || allSpans.length == 0) {
    return;
  }

  for (let i = 0; i < allSpans.length; i++) {
    let spanStart = allSpans[i].getAttribute("data-start");
    let spanEnd = allSpans[i].getAttribute("data-end");
    allSpans[i].classList.remove("highlight-orange");
    if (
      spanStart &&
      spanEnd &&
      Number(spanStart) >= result.start &&
      Number(spanEnd) <= result.end
    ) {
      allSpans[i].classList.add("highlight-orange");
    }
  }
};

const languageDictionary: { [key: string]: string } = {
  AR: "Arabic",
  BG: "Bulgarian",
  CS: "Czech",
  DA: "Danish",
  DE: "German",
  EL: "Greek",
  EN: "English",
  "EN-GB": "English",
  ES: "Spanish",
  ET: "Estonian",
  FI: "Finnish",
  FR: "French",
  HU: "Hungarian",
  ID: "Indonesian",
  IT: "Italian",
  JA: "Japanese",
  KO: "Korean",
  LT: "Lithuanian",
  LV: "Latvian",
  NB: "Norwegian (Bokmål)",
  NL: "Dutch",
  PL: "Polish",
  "PT-BR": "Portuguese (Brazilian)",
  "PT-PT": "Portuguese",
  RO: "Romanian",
  RU: "Russian",
  SK: "Slovak",
  SL: "Slovenian",
  SV: "Swedish",
  TR: "Turkish",
  UK: "Ukrainian",
  ZH: "Chinese (simplified)",
};

export const getLanguageNames = (languageCodes?: string[]) => {
  if (!languageCodes) return undefined;
  let names = languageCodes.map((code) => {
    if (languageDictionary[code]) {
      return { code: code, name: languageDictionary[code] };
    } else {
      return { code: code, name: "Unknown" };
    }
  });
  return names;
};

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

//Current method can create duplicates, if this becomes problem in the editor, add check to prevent duplicates
function createDynamicStyle(color: string) {
  const style = document.createElement("style");
  style.textContent = `
    .color-${color.slice(1)} {
      background-color: ${hexToRGBA(color, 0.4)};
    }
  `;
  document.head.append(style);
}

export const createTagColorsCSS = (tags: TagModel[]) => {
  tags.forEach((tag) => {
    createDynamicStyle(tag.color);
  });
};

export const getOffsetTopRelativeToEventTranscript = (element: any) => {
  const transcriptEditor = document.querySelector(".scrollable");
  return getAbsoluteOffsetTop(element, transcriptEditor);
};

export const getEventTranscriptOffsetTop = () => {
  const transcriptEditor = document.querySelector(".scrollable");
  return getAbsoluteOffsetTop(transcriptEditor, null);
};

export const splitWordsByHighlight = (
  wordOrginal: WordModel,
  result: TextSearchResult
) => {
  const word = { ...wordOrginal };
  const splitWords: {original: WordModel, split: WordModel[]} = {original: wordOrginal, split: []};
  const wordText = word.text;

  var firstWord: WordModel | undefined = { ...word };
  var secondWord: WordModel | undefined = { ...word };
  var thirdWord: WordModel | undefined = { ...word };

  if (
    result.offsetRight &&
    result.offsetRight > 0 &&
    result.offsetLeft &&
    result.offsetLeft > 0
  ) {
    const prevText = wordText.substring(0, result.offsetLeft);
    const resultText = wordText.substring(
      result.offsetLeft,
      word.text.length - result.offsetRight
    );
    const remainingText = wordText.substring(
      word.text.length - result.offsetRight,
      wordText.length
    );

    firstWord = {
      ...word,
      text: prevText,
      start: word.start - 1,
      end: word.end - 1,
    };
    secondWord = { ...word, text: resultText };
    thirdWord = {
      ...word,
      text: remainingText,
      start: word.start + 1,
      end: word.end + 1,
    };

    splitWords.split.push(firstWord, secondWord, thirdWord);
  } else if (result.offsetLeft && result.offsetLeft > 0) {
    const prevText = wordText.substring(0, result.offsetLeft);
    const resultText = wordText.substring(result.offsetLeft, wordText.length);

    firstWord = {
      ...word,
      text: prevText,
      start: word.start - 1,
      end: word.end - 1,
    };
    secondWord = { ...word, text: resultText };

    splitWords.split.push(firstWord, secondWord);
  } else if (result.offsetRight && result.offsetRight > 0) {
    const resultText = wordText.substring(
      0,
      word.text.length - result.offsetRight
    );
    const remainingText = wordText.substring(
      word.text.length - result.offsetRight,
      wordText.length
    );

    firstWord = { ...word, text: resultText };
    secondWord = {
      ...word,
      text: remainingText,
      start: word.start + 1,
      end: word.end + 1,
    };

    splitWords.split.push(firstWord, secondWord);
  }

  return splitWords;
};

import image from './Untitled.png'

export const generateThumbnail = (videoUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.crossOrigin = 'anonymous';
    video.src = videoUrl;
    video.muted = true;
    video.playsInline = true;

    const onLoadedMetadata = () => {
      video.pause();
      video.currentTime = 300;
    };

    const onSeeked = () => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        try {
          const dataURL = canvas.toDataURL('image/png');
          cleanup();
          resolve(dataURL);
        } catch (error) {
          cleanup();
          reject('Canvas is tainted due to cross-origin video source.');
        }
      } else {
        cleanup();
        reject('Could not get canvas context');
      }
    };

    const onError = () => {
      cleanup();
      reject('Error loading video');
    };

    const cleanup = () => {
      video.removeEventListener('loadedmetadata', onLoadedMetadata);
      video.removeEventListener('seeked', onSeeked);
      video.removeEventListener('error', onError);
      video.src = '';
      video.load();
      if (video.parentNode) {
        video.parentNode.removeChild(video);
      }
    };

    video.addEventListener('loadedmetadata', onLoadedMetadata);
    video.addEventListener('seeked', onSeeked);
    video.addEventListener('error', onError);

    video.load();
  });
};