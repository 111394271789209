import { FC, useEffect, useState } from "react";
import { useLocation, useMatch, useParams } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import { ProfilePicture } from "./ProfilePicture";

const LogoutRedirect = import.meta.env.VITE_APP_LOGOUT_REDIRECT;
const baseUrl = import.meta.env.VITE_APP_BACKEND_URL;

const HeaderUserMenu: FC = () => {
  const { currentUser } = useAuth();
  const [isDashboard, setIsDashboard] = useState(false);
  const [logo, setLogo] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const location = useLocation();
  const company = location.pathname.split("/")[2];

  const match = useMatch("/dashboard/*");

  useEffect(() => {
    const getCurrentCompanyProps = () => {
      const currentCompany = currentUser?.companies_managed?.find(
        (value) => value.slug == company
      );
      const logo = currentCompany?.logo;
      const name = currentCompany?.name;
      return { logo, name };
    };

    if (match && currentUser) {
      const { logo, name } = getCurrentCompanyProps();
      if (logo && name) {
        setLogo(logo);
        setName(name);
      }
      setIsDashboard(true);
    }
  }, [match]);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      {isDashboard && currentUser && logo ? (
        <>
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="symbol symbol-50px me-5">
                <img alt="Logo" src={logo} />
              </div>

              <div className="d-flex flex-column">
                <div className="fw-bold d-flex align-items-center fs-5">
                  {name}
                </div>
                <div className="text-muted fs-7">
                  Logged in as{" "}
                  <a href="#" className="fw-semibold text-hover-primary fs-7">
                    {currentUser?.first_name + " " + currentUser?.last_name}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="separator my-2"></div>

          <div className="mh-400px overflow-scroll">
            {currentUser!.companies_managed!.length > 1 && (
              <div className=" mx-3 my-2 fw-bold">Change active company:</div>
            )}

            {currentUser!.companies_managed!.map((company) => (
              <div key={company.id} className="menu-item px-3 my-0">
                <a
                  href={`${baseUrl}/dashboard/${company.slug}`}
                  className="menu-link px-3 py-2"
                  data-kt-element="mode"
                  data-kt-value="light"
                >
                  <div className="symbol symbol-35px me-5">
                    <img alt="Logo" src={company.logo} />
                  </div>

                  <span className="menu-title">{company.name}</span>
                </a>
              </div>
            ))}
          </div>

          <div className="menu-item px-5">
            <a href={`${LogoutRedirect}`} className="menu-link px-5">
              Sign Out
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="menu-item px-3">
            <div className="menu-content d-flex align-items-center px-3">
              <div className="symbol symbol-50px me-5">
                <ProfilePicture isMenu={true} />
              </div>

              <div className="d-flex flex-column">
                <div className="fw-bolder d-flex align-items-center fs-5">
                  {currentUser?.first_name} {currentUser?.last_name}
                </div>
                <a
                  href="#"
                  className="fw-bold text-muted text-hover-primary fs-7"
                >
                  {currentUser?.email}
                </a>
              </div>
            </div>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-5">
            <a href={`${baseUrl}/account`} className="menu-link px-5">
              My Profile
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-5">
            <a
              href={"https://stokk.io/privacy-policy"}
              className="menu-link px-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>

          <div className="menu-item px-5">
            <a
              href={"https://stokk.io/disclaimer"}
              className="menu-link px-5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Disclaimer
            </a>
          </div>

          <div className="menu-item px-5">
            <a
              className="menu-link px-5"
              type="button"
              onClick={() => {
                const prefBnt = document.querySelector(
                  ".termly-display-preferences"
                ) as HTMLButtonElement;
                if (prefBnt) {
                  prefBnt.click();
                }
              }}
            >
              Cookie Preferences
            </a>
          </div>

          <div className="separator my-2"></div>

          <div className="menu-item px-5">
            <a href={`${LogoutRedirect}`} className="menu-link px-5">
              Sign Out
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export { HeaderUserMenu };
